import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import './index.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '/api';

const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [downloadLink, setDownloadLink] = useState(null);
  const chatMessagesRef = useRef(null);

  const smoothScrollToBottom = useCallback(() => {
    if (chatMessagesRef.current) {
      const element = chatMessagesRef.current;
      element.scrollTop = element.scrollHeight;
    }
  }, []);

  useEffect(() => {
    smoothScrollToBottom();
  }, [messages, smoothScrollToBottom]);

  const appendMessage = (message, isUser) => {
    const newMessage = { content: message, isUser };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };

  useEffect(() => {
  appendMessage(
    `Benvenuto! Sono qui per aiutarti a creare il tuo dataset per l'assistenza clienti di primo livello. 
    Ti farò 10 domande a cui ti chiedo di rispondere nel modo più approfondito possibile. 
    Se tra le domande non ti verrà richiesto di fornire i tuoi contatti (come numero di telefono, email, sito web o indirizzo), 
    ti invito ad aggiungerli manualmente alla fine delle risposte. Questo ci aiuterà a creare un dataset completo per il tuo business.`,
    false
  );
  }, []);

  const getAccessToken = () => {
    return localStorage.getItem('token');
  };

  const extractDownloadLink = (content) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const match = content.match(urlRegex);
    return match ? match[0] : null;
  };

  const sendMessage = async () => {
    if (!input.trim()) return;
    appendMessage(input, true);
    setInput('');
    setLoading(true);

    const token = getAccessToken();
    if (!token) {
      appendMessage('Sessione scaduta. Effettua nuovamente il login.', false);
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/chat`, 
        { message: input },
        { 
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      const responseMessage = response.data.response;
      appendMessage(responseMessage, false);

      // Cerca un link di download nella risposta
      const link = extractDownloadLink(responseMessage);
      if (link) {
        setDownloadLink(link);
      }

    } catch (error) {
      console.error('Errore durante la chat:', error);
      if (error.response && error.response.status === 401) {
        appendMessage('Errore di autenticazione. Prova a effettuare nuovamente il login.', false);
      } else {
        appendMessage('Si è verificato un errore. Riprova più tardi.', false);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async () => {
    if (downloadLink) {
      const token = getAccessToken();
      if (!token) {
        appendMessage('Sessione scaduta. Effettua nuovamente il login prima di scaricare il file.', false);
        return;
      }

      try {
        const response = await axios.get(downloadLink, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          responseType: 'blob'
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'dataset.csv');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        appendMessage('Download completato con successo!', false);
      } catch (error) {
        console.error('Errore durante il download del file:', error);
        if (error.response) {
          console.error('Risposta del server:', error.response);
          appendMessage(`Errore durante il download del file: ${error.response.status} - ${error.response.statusText}`, false);
        } else if (error.request) {
          console.error('Nessuna risposta ricevuta:', error.request);
          appendMessage('Errore di rete durante il download del file. Verifica la tua connessione e riprova.', false);
        } else {
          console.error('Errore durante la configurazione della richiesta:', error.message);
          appendMessage('Si è verificato un errore imprevisto durante il download del file. Riprova più tardi.', false);
        }
      }
    }
  };

  return (
    <div className="container mx-auto p-4">
      <div id="chat-container" className="bg-white rounded shadow-lg p-6">
        <div id="chat-messages" className="h-80 overflow-y-auto mb-4" ref={chatMessagesRef}>
          {messages.map((message, index) => (
            <div
              key={index}
              className={`message p-2 mb-2 rounded max-w-lg ${message.isUser ? 'user-message bg-blue-100 self-end' : 'bot-message bg-gray-100'}`}
            >
              {message.content}
            </div>
          ))}
          {loading && (
            <div className="message p-2 mb-2 rounded max-w-lg bot-message bg-gray-100">
              Sto elaborando la risposta...
            </div>
          )}
        </div>

        {downloadLink && (
          <div className="mb-4">
            <button
              className="bg-green-600 text-white rounded-lg px-4 py-2 hover:bg-green-500"
              onClick={handleDownload}
            >
              Scarica il dataset
            </button>
          </div>
        )}

        <div id="input-container" className="flex items-center space-x-4">
          <input
            type="text"
            className="border rounded-lg w-full p-3"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Digita il tuo messaggio qui..."
            onKeyDown={(e) => {
              if (e.key === 'Enter') sendMessage();
            }}
            disabled={loading}
          />
          <button
            className={`bg-blue-600 text-white rounded-lg px-4 py-2 hover:bg-blue-500 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={sendMessage}
            disabled={loading}
          >
            Invia
          </button>
        </div>
      </div>
    </div>
  );
};

export default Chatbot;