import React, { useState, useCallback } from 'react';
import axios from 'axios';
import './index.css';
import { 
  ArrowDownTrayIcon, 
  ArrowUpTrayIcon, 
  DocumentIcon, 
  ExclamationCircleIcon,
  CheckCircleIcon,
  CpuChipIcon 
} from '@heroicons/react/24/outline';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '/api';

const Dashboard = ({ onLogout }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [purpose] = useState('assistenza_clienti_primo_livello');
  const [messages, setMessages] = useState([]);
  const [jsonFilePath, setJsonFilePath] = useState(null);
  const [jsonPreview, setJsonPreview] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [previewData, setPreviewData] = useState([]);
  const [useExpansion, setUseExpansion] = useState(true);
  const [numVariants, setNumVariants] = useState(3);
  const [fineTuneStatus, setFineTuneStatus] = useState(null);
  const [isFineTuning, setIsFineTuning] = useState(false);

  const appendMessage = useCallback((message, isUser = false) => {
    setMessages(prevMessages => [...prevMessages, { content: message, isUser }]);
  }, []);

  const handleDownloadTemplate = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      appendMessage('Token mancante. Effettua nuovamente il login.', false);
      return;
    }
    try {
      const response = await axios.get(`${API_BASE_URL}/download_template/${purpose}`, {
        responseType: 'blob',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${purpose}_template.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      appendMessage('Template scaricato con successo. Puoi usarlo come riferimento per preparare il tuo file.', false);
    } catch (error) {
      console.error('Errore durante il download del template:', error);
      appendMessage('Errore durante il download del template. Riprova più tardi.', false);
    }
  };

  const handleFileChange = (e) => {
    setSelectedFiles(Array.from(e.target.files));
  };

  const handleFileUpload = async () => {
    if (selectedFiles.length > 0) {
      const formData = new FormData();
      selectedFiles.forEach((file) => {
        formData.append('files', file);
      });
      formData.append('purpose', purpose);
      formData.append('use_expansion', useExpansion);
      formData.append('num_variants', numVariants);

      const token = localStorage.getItem('token');
      if (!token) {
        appendMessage('Token mancante. Effettua nuovamente il login.', false);
        return;
      }

      try {
        const response = await axios.post(`${API_BASE_URL}/upload_preview`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
          },
        });

        if (response.data.results) {
          response.data.results.forEach((result) => {
            if (result.error) {
              appendMessage(`Errore nel file ${result.filename}: ${result.error}`, false);
            } else {
              appendMessage(`File caricato: ${result.filename}`, false);

              // Salva l'anteprima dei dati nello stato
              if (result.preview) {
                setPreviewData(result.preview);
              }

              if (result.problems && result.problems.length > 0) {
                appendMessage(`Problemi rilevati: ${result.problems.join(', ')}`, false);
              }

              if (result.improvement_suggestions && result.improvement_suggestions.length > 0) {
                appendMessage(`Suggerimenti di miglioramento: ${result.improvement_suggestions.join(', ')}`, false);
              }

              if (result.removed_rows && result.removed_rows.length > 0) {
                appendMessage(`Attenzione: Sono state rimosse ${result.removed_rows.length} righe per dati incompleti.`, false);
                appendMessage(`Dettagli delle righe rimosse: ${result.removed_rows.join(', ')}`, false);
              }

              // Controlla se ci sono problemi critici
              if (result.critical_problems && result.critical_problems.length > 0) {
                appendMessage(`Sono stati rilevati problemi critici: ${result.critical_problems.join(', ')}`, false);
                appendMessage(`Vuoi correggere il file o procedere comunque?`, false);

                appendMessage(
                  <div className="mt-2 space-x-2">
                    <button 
                      onClick={() => handleCorrectFile(result.filename)}
                      className="px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition-colors"
                    >
                      Correggi File
                    </button>
                    <button 
                      onClick={() => handleProceedAnyway(result.filename)}
                      className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
                    >
                      Procedi comunque
                    </button>
                  </div>,
                  false
                );
              } else {
                appendMessage('Il file è valido e pronto per il fine-tuning.', false);
                // Mostra il pulsante per procedere con la generazione del JSON
                appendMessage(
                  <button
                    onClick={handleGenerateJson}
                    className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mt-4"
                  >
                    Procedi
                  </button>,
                  false
                );
              }
            }
          });
        }
      } catch (error) {
        console.error('Errore durante il caricamento dei file:', error);
        appendMessage(`Errore durante il caricamento dei file: ${error.response?.data?.detail || error.message}`, false);
      }
    } else {
      appendMessage('Per favore, seleziona almeno un file da caricare.', false);
    }
  };

  // Funzioni per gestire la correzione e il procedere comunque
  const handleCorrectFile = (filename) => {
    appendMessage(`Per favore, correggi il file originale e ricaricalo.`, false);
    // Puoi aggiungere logica per resettare lo stato o fornire ulteriori istruzioni
  };

  const handleProceedAnyway = async (filename) => {
    appendMessage(`Hai scelto di procedere con il fine-tuning per il file ${filename} nonostante i problemi rilevati. Generazione del JSON in corso...`, false);
    
    try {
      await handleGenerateJson(true);
    } catch (error) {
      appendMessage('Errore durante la generazione del JSON. Verifica i dati e riprova.', false);
      console.error('Errore durante handleProceedAnyway:', error);
    }
  };

const handleGenerateJson = async (fromProceedAnyway = false) => {
  console.log("Inizio generazione JSON");
  if (selectedFiles.length === 0) {
    console.log("Nessun file selezionato");
    appendMessage('Per favore, seleziona almeno un file da processare.', false);
    return;
  }

  setIsProcessing(true);
  setProgress(0);
  console.log(`File selezionati: ${selectedFiles.map(f => f.name).join(', ')}`);

  const formData = new FormData();
  selectedFiles.forEach((file) => {
    formData.append('files', file);
  });
  formData.append('purpose', purpose);
  formData.append('use_expansion', useExpansion);
  formData.append('num_variants', numVariants);

  const token = localStorage.getItem('token');
  if (!token) {
    console.error("Token mancante");
    appendMessage('Token mancante. Effettua nuovamente il login.', false);
    setIsProcessing(false);
    return;
  }

  try {
    console.log("Invio richiesta di conversione JSON al server");
    const response = await axios.post(`${API_BASE_URL}/convert_to_json`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
    });

    console.log("Risposta ricevuta dal server:", response.data);

    if (response.data.task_id) {
      appendMessage(`Processo di conversione avviato. ID del task: ${response.data.task_id}`, false);
      await pollTaskStatus(response.data.task_id);
    } else {
      console.error('task_id non presente nella risposta:', response.data);
      appendMessage('Errore: Il processo di conversione non è stato avviato correttamente.', false);
    }
  } catch (error) {
    console.error('Errore durante la generazione del JSON:', error);
    appendMessage('Errore durante la generazione del file JSON. Riprova più tardi.', false);
  } finally {
    setIsProcessing(false);
  }
};


const pollTaskStatus = async (taskId) => {
  const maxAttempts = 60;
  const interval = 5000; // 5 secondi
  for (let attempt = 0; attempt < maxAttempts; attempt++) {
    try {
      console.log(`Tentativo ${attempt + 1} di controllare lo stato del task ${taskId}`);
      const response = await axios.get(`${API_BASE_URL}/task_status/${taskId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      console.log("Stato del task:", response.data);

      // Aggiorna la barra di progresso in base allo stato del task
      if (response.data.state === 'PROCESSING') {
        setProgress(50);  // Se il task è in elaborazione, metti il progresso al 50%
        appendMessage(`Stato del processo: ${response.data.state} - ${response.data.status}`, false);
      } else if (response.data.state === 'SUCCESS') {
        setProgress(100);  // Una volta completato, setta la barra al 100%
        appendMessage(`Processo completato con successo. File JSON generato.`, false);
        if (response.data.json_file_path) {
          setJsonFilePath(response.data.json_file_path);
          appendMessage(`File JSON pronto per il fine-tuning: ${response.data.json_file_path}`, false);
          
          // Visualizza l'anteprima del JSONL
          if (response.data.json_preview && response.data.json_preview.length > 0) {
            setJsonPreview(response.data.json_preview);
          }
          
          appendMessage(
            <button
              onClick={() => handleSendForFinetuning(response.data.json_file_path)}
              className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-4"
            >
              <CpuChipIcon className="mr-2 h-4 w-4" />
              Invia per Fine-Tuning
            </button>,
            false
          );
        }
        return;
      } else if (response.data.state === 'FAILURE') {
        setProgress(0);  // Se fallisce, resetta la barra a 0
        appendMessage(`Errore durante la generazione del JSON: ${response.data.status}`, false);
        return;
      }

      // Aggiorna il progresso gradualmente in base al numero di tentativi
      setProgress((attempt / maxAttempts) * 100);  // Calcola un progresso basato sul numero di tentativi

      await new Promise(resolve => setTimeout(resolve, interval));
    } catch (error) {
      console.error('Errore durante il controllo dello stato del task:', error);
      appendMessage(`Errore durante il controllo dello stato del task: ${error.message}`, false);
      return;
    }
  }
  appendMessage('Timeout durante la generazione del JSON. Controlla lo stato più tardi o riprova.', false);
};


const checkTaskStatus = async (taskId) => {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error("Token mancante");
    appendMessage('Token mancante. Effettua nuovamente il login.', false);
    return;
  }

  const maxAttempts = 60; // Numero massimo di tentativi
  const delayBetweenAttempts = 5000; // 5 secondi di attesa tra i tentativi

  for (let attempt = 0; attempt < maxAttempts; attempt++) {
    try {
      const response = await axios.get(`${API_BASE_URL}/task_status/${taskId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      console.log("Stato del task:", response.data);

      if (response.data.state === 'SUCCESS') {
        if (response.data.json_file_path) {
          setJsonFilePath(response.data.json_file_path);
          appendMessage(`File JSON pronto per il fine-tuning: ${response.data.json_file_path}`, false);
          appendMessage(
            <button
              onClick={() => handleSendForFinetuning(response.data.json_file_path)}
              className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-4"
            >
              <CpuChipIcon className="mr-2 h-4 w-4" />
              Invia per Fine-Tuning
            </button>,
            false
          );
        } else {
          appendMessage('Errore: Il file JSON non è stato generato correttamente.', false);
        }
        return;
      } else if (response.data.state === 'FAILURE') {
        appendMessage('Errore durante la generazione del JSON. Riprova più tardi.', false);
        return;
      } else {
        appendMessage(`Stato del processo: ${response.data.status}`, false);
      }
    } catch (error) {
      console.error('Errore durante il controllo dello stato del task:', error);
    }

    // Attendi prima del prossimo tentativo
    await new Promise(resolve => setTimeout(resolve, delayBetweenAttempts));
  }

  appendMessage('Timeout nella generazione del JSON. Controlla lo stato più tardi o riprova.', false);
};

const handleSendForFinetuning = async (jsonFilePathParam) => {
  console.log("Inizio processo di invio per fine-tuning");
  console.log("jsonFilePath ricevuto:", jsonFilePathParam);
  
  if (typeof jsonFilePathParam !== 'string') {
    console.error("jsonFilePath non è una stringa valida:", jsonFilePathParam);
    appendMessage('Errore: Il percorso del file JSON non è valido.', false);
    return;
  }

  try {
    setIsFineTuning(true);
    const formData = new FormData();
    formData.append('json_file_path', jsonFilePathParam);
    formData.append('purpose', purpose);

    const token = localStorage.getItem('token');
    if (!token) {
      console.error("Token mancante");
      appendMessage('Token mancante. Effettua nuovamente il login.', false);
      setIsFineTuning(false);
      return;
    }

    console.log("Invio richiesta di fine-tuning al server");
    const res = await axios.post(`${API_BASE_URL}/process_finetuning`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
    });

    console.log("Risposta dal server per l'avvio del fine-tuning:", res.data);
    appendMessage('Il processo di fine-tuning è stato avviato.', false);
    monitorFineTuneStatus();
  } catch (error) {
    console.error('Errore durante l\'invio per il fine-tuning:', error);
    appendMessage(`Errore durante l'invio per il fine-tuning: ${error.message}`, false);
  } finally {
    setIsFineTuning(false);
  }
};


 const monitorFineTuneStatus = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    appendMessage('Token mancante. Effettua nuovamente il login.', false);
    return;
  }

  const interval = setInterval(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/fine_tune_status`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      const jobs = response.data.jobs;
      if (jobs.length > 0) {
        const latestJob = jobs[0];  // Assume che i job siano ordinati per data di creazione decrescente
        setFineTuneStatus(latestJob.status);
        if (latestJob.status === 'succeeded') {
          appendMessage(`Fine-tuning completato. Job ID: ${latestJob.job_id}`, false);
          clearInterval(interval);
        } else if (latestJob.status === 'failed') {
          appendMessage(`Il processo di fine-tuning è fallito. Job ID: ${latestJob.job_id}`, false);
          clearInterval(interval);
        } else {
          appendMessage(`Stato del fine-tuning: ${latestJob.status}. Job ID: ${latestJob.job_id}`, false);
        }
      } else {
        appendMessage('Nessun job di fine-tuning trovato.', false);
      }
    } catch (error) {
      console.error('Errore durante il monitoraggio dello stato del fine-tuning:', error);
      appendMessage('Errore durante il monitoraggio dello stato del fine-tuning.', false);
      clearInterval(interval);
    }
  }, 30000);  // Controlla ogni 30 secondi
};

  return (
    <div className="min-h-screen bg-gray-100">
      <nav className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <CpuChipIcon  className="h-6 w-6 text-indigo-600" /> 
                <span className="ml-2 text-lg font-semibold text-gray-800">Dashboard AI</span>
              </div>
            </div>
            <div className="flex items-center">
              <button
                onClick={onLogout}
                className="ml-3 inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </nav>

      <div className="py-6">
        <header>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-xl font-bold leading-tight text-gray-900">Carica File</h1>
            <h2 className="text-lg font-semibold text-gray-900 mt-8">Guida all'Utilizzo</h2>
            <p className="mt-2 text-gray-600">
              Benvenuti nella guida per l'utilizzo dell'applicazione FinetuningMax, progettata per assistervi nella preparazione di dati non strutturati per il fine-tuning di modelli AI. Seguite i passi della guida per ottenere il massimo dai vostri dati.
            </p>
            <ul className="list-disc ml-5 mt-4">
              <li><strong>Accesso all'Applicazione:</strong> Accedete e selezionate l'area d'intervento appropriata per il vostro scopo.</li>
              <li><strong>Caricamento File:</strong> Caricate i vostri file (PDF, Word, CSV, Excel) per l'elaborazione.</li>
              <li><strong>Valutazione del File:</strong> Visualizzate l'anteprima e le statistiche dei vostri dati, correggete eventuali problemi con l'aiuto del chatbot.</li>
              <li><strong>Correzione e Conferma:</strong> Applicate le modifiche suggerite e confermate la validità dei dati.</li>
              <li><strong>Generazione Template:</strong> Utilizzate il template di esempio per preparare ulteriori dati.</li>
              <li><strong>Preparazione per il Fine-Tuning:</strong> Confermate e inviate i dati per il fine-tuning del modello AI.</li>
            </ul>
          </div>
        </header>
        <main>
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="px-4 py-6 sm:px-0">
              <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-1 gap-4">
                    {/* Sezione per la personalizzazione dell'espansione del dataset */}
                    <div className="mt-4">
                      <h3 className="text-lg font-medium text-gray-900">Opzioni di espansione del dataset</h3>
                      <div className="mt-2">
                        <label className="inline-flex items-center">
                          <input
                            type="checkbox"
                            checked={useExpansion}
                            onChange={(e) => setUseExpansion(e.target.checked)}
                            className="form-checkbox h-5 w-5 text-blue-600"
                          />
                          <span className="ml-2 text-gray-700">Usa espansione del dataset</span>
                        </label>
                      </div>
                      {useExpansion && (
                        <div className="mt-2">
                          <label className="block text-sm font-medium text-gray-700">
                            Numero di varianti per domanda/risposta
                          </label>
                          <input
                            type="number"
                            min="1"
                            max="5"
                            value={numVariants}
                            onChange={(e) => setNumVariants(parseInt(e.target.value))}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                          />
                        </div>
                      )}
                    </div>
                    <div>
                      <button
                        onClick={handleDownloadTemplate}
                        className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        <ArrowDownTrayIcon  className="mr-2 h-4 w-4" /> 
                        Scarica Template
                      </button>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Carica file
                      </label>
                      <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                        <div className="space-y-1 text-center">
                          <ArrowUpTrayIcon className="mx-auto h-8 w-8 text-gray-400" /> 
                          <div className="flex text-sm text-gray-600">
                            <label
                              htmlFor="file-upload"
                              className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                            >
                              <span>Carica un file</span>
                              <input id="file-upload" name="file-upload" type="file" className="sr-only" onChange={handleFileChange} multiple />
                            </label>
                            <p className="pl-1">o trascina e rilascia</p>
                          </div>
                          <p className="text-xs text-gray-500">
                            CSV, Excel, Word, PDF fino a 10MB
                          </p>
                        </div>
                      </div>
                    </div>
                    {selectedFiles.length > 0 && (
                      <div>
                        <h4 className="text-sm font-medium text-gray-700">File selezionati:</h4>
                        <ul className="mt-2 border border-gray-200 rounded-md divide-y divide-gray-200">
                          {selectedFiles.map((file, index) => (
                            <li key={index} className="pl-3 pr-4 py-2 flex items-center justify-between text-sm">
                              <div className="w-0 flex-1 flex items-center">
                                <DocumentIcon  className="flex-shrink-0 h-5 w-5 text-gray-400" /> 
                                <span className="ml-2 flex-1 w-0 truncate">{file.name}</span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    <div className="flex space-x-4">
                      <button
                        onClick={handleFileUpload}
                        disabled={isProcessing}
                        className={`inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                          isProcessing ? 'bg-gray-400 cursor-not-allowed' : ''
                        }`}
                      >
                        <ArrowUpTrayIcon className="mr-2 h-4 w-4" />
                        {isProcessing ? 'Elaborazione in corso...' : 'Carica File e Visualizza Anteprima'}
                      </button>
                      
                     
                    </div>
                   

                    {/* Anteprima dei Dati */}
                    {previewData.length > 0 && (
                      <div className="mt-4">
                        <h3 className="text-lg font-medium text-gray-900">Anteprima dei Dati</h3>
                        <table className="mt-2 table-auto w-full">
                          <thead>
                            <tr>
                              {Object.keys(previewData[0]).map((col) => (
                                col !== 'status' && (
                                  <th key={col} className="px-4 py-2 text-left text-sm font-medium text-gray-700 border-b">
                                    {col.charAt(0).toUpperCase() + col.slice(1)}
                                  </th>
                                )
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {previewData.map((row, index) => (
                              <tr key={index} className={row.status === 'valid' ? 'bg-green-100' : 'bg-red-100'}>
                                {Object.keys(row).map((col) => (
                                  col !== 'status' && (
                                    <td key={col} className="border px-4 py-2 text-sm text-gray-800">
                                      {row[col]}
                                    </td>
                                  )
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                    
                     {isProcessing && (
                      <div className="mt-4">
                        <h3 className="text-lg font-medium text-gray-900">Progresso elaborazione</h3>
                        <div className="mt-2 relative pt-1">
                          <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
                            <div 
                              style={{ width: `${progress}%` }}
                              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500 transition-all duration-500 ease-in-out"
                            ></div>
                          </div>
                          <div className="text-right">
                            <span className="text-sm font-semibold inline-block text-blue-600">
                              {progress}%
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* Anteprima del JSONL generato */}
                    {jsonPreview && (
                      <div className="mt-4">
                        <h3 className="text-lg font-medium text-gray-900">Anteprima del JSONL generato</h3>
                        <pre className="mt-2 p-4 bg-gray-100 border rounded-md text-sm text-gray-800 overflow-auto max-h-60">
                          {jsonPreview.map((line, index) => (
                            <div key={index}>{line}</div>
                          ))}
                        </pre>
                      </div>
                    )}

                    {/* Messaggi del Sistema */}
                    {messages.length > 0 && (
                      <div className="mt-4">
                        <h3 className="text-lg font-medium text-gray-900">Messaggi del sistema</h3>
                        <div className="mt-2 border border-gray-200 rounded-md divide-y divide-gray-200 max-h-60 overflow-y-auto">
                          {messages.map((message, index) => (
                            <div key={index} className={`p-3 ${message.isUser ? 'bg-blue-50' : 'bg-white'}`}>
                              <p className="text-sm text-gray-700 flex items-start">
                                {message.isUser ? (
                                  <ExclamationCircleIcon  className="h-5 w-5 text-blue-500 mr-2 flex-shrink-0" /> 
                                ) : (
                                  <CheckCircleIcon  className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" /> 
                                )}
                                {message.content}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {/* Stato del Fine-Tuning */}
                    {fineTuneStatus && (
                      <div className="mt-4">
                        <h3 className="text-lg font-medium text-gray-900">Stato del Fine-Tuning</h3>
                        <p className="mt-2 text-gray-700">
                          {fineTuneStatus === 'running' && 'Fine-tuning in corso...'}
                          {fineTuneStatus === 'succeeded' && 'Fine-tuning completato con successo!'}
                          {fineTuneStatus === 'failed' && 'Fine-tuning fallito.'}
                        </p>
                      </div>
                    )}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;

