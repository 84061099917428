import React, { useState, useEffect } from 'react';
import Dashboard from './Dashboard';
import Chatbot from './Chatbot';
import Login from './Login';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const [currentView, setCurrentView] = useState('dashboard'); // 'dashboard' o 'chatbot'

  useEffect(() => {
    if (token) {
      setIsLoggedIn(true);
    }
  }, [token]);

  const handleLogin = (newToken) => {
    setToken(newToken);
    localStorage.setItem('token', newToken);
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setToken(null);
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    setCurrentView('dashboard');
  };

  const toggleView = () => {
    setCurrentView(currentView === 'dashboard' ? 'chatbot' : 'dashboard');
  };

  if (!isLoggedIn) {
    return <Login setIsLoggedIn={setIsLoggedIn} setToken={setToken} />;
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <nav className="bg-white shadow-lg">
        <div className="max-w-6xl mx-auto px-4">
          <div className="flex justify-between">
            <div className="flex space-x-7">
              <div>
                <a href="#" className="flex items-center py-4 px-2">
                  <span className="font-semibold text-gray-500 text-lg">AI Assistant</span>
                </a>
              </div>
            </div>
            <div className="flex items-center space-x-3">
              <button
                onClick={toggleView}
                className="py-2 px-2 font-medium text-gray-500 rounded hover:bg-blue-500 hover:text-white transition duration-300"
              >
                {currentView === 'dashboard' ? 'Switch to Chatbot' : 'Switch to Dashboard'}
              </button>
              <button
                onClick={handleLogout}
                className="py-2 px-2 font-medium text-gray-500 rounded hover:bg-red-500 hover:text-white transition duration-300"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </nav>

      <div className="container mx-auto p-6">
        {currentView === 'dashboard' ? (
          <Dashboard 
            isLoggedIn={isLoggedIn} 
            token={token} 
            onLogout={handleLogout}
          />
        ) : (
          <Chatbot token={token} />
        )}
      </div>
    </div>
  );
};

export default App;